import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.PRODUCTION,
  release: '5fb902ee35f6d042b6f878bf75dc9585e05d2ea5',
  sentryDSN: undefined,
  websiteURL: 'http://teslaeesti.com/',
  apiURLFrontend: '/api_api/',
  plausible: {
    siteId: 'teslaeesti.com',
    url: 'https://plausible.freeyourmusic.com/'
  }
};
